import React from 'react'

import Subscription from '../../components/views/profile/subscription'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function ProfileSubscriptionPage() {
   return (
      <LayoutLoggedIn>
         <Subscription />
      </LayoutLoggedIn>
   )
}
